import { gsap } from 'gsap/all';
import { DrawSVGPlugin } from '../lib/gsap-extras/DrawSVGPlugin';
gsap.registerPlugin(DrawSVGPlugin);

export function draw() {
	const timeline = gsap.timeline({ delay: 0.5 });

	timeline
		.set('.js-ue-logo', {
			visibility: 'visible'
		})
		.from('#ue-logo--bottom-half', {
			drawSVG: '0%',
			duration: 1.5
		})
		.from('#ue-logo--dot', {
			opacity: 0,
			duration: 0.5
		})
		.from('#ue-logo--dot', {
			x: 20,
			duration: 0.5,
			ease: 'back.out(1)'
		})
		.from('#ue-logo--top-half', {
			drawSVG: '0%',
			duration: 1.5
		})
		.from('#ue-logo--circle', {
			rotation: '-360',
			transformOrigin: 'center',
			duration: 2,
			ease: 'back.out(0.5)'
		})
		.from('#ue-logo--text', {
			opacity: 0,
			duration: 1
		})

		return timeline;
};
