import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const hero = document.querySelector(".js-hero-banner");

const tl = gsap.timeline({
	scrollTrigger: {
		trigger: hero,
		start: "top bottom",
		end: "+=200",
		scrub: 1,
	},
});

tl.from(hero, {
	scale: 0.6,
});
