import debounce from '../utilities/debounce';

(function (document, window, index) {
	'use strict';

	var elSelector	= 'header',
		element		= document.querySelector(elSelector);

	if (!element) return true;

	var elHeight		= 0,
		elTop			= 0,
		dHeight			= 0,
		wHeight			= 0,
		wScrollCurrent	= 0,
		wScrollBefore	= 0,
		wScrollDiff		= 0;

	const hideOnScroll = debounce(function() {
		elHeight		= element.offsetHeight;
		dHeight			= document.body.offsetHeight;
		wHeight			= window.innerHeight;
		wScrollCurrent	= window.pageYOffset;
		wScrollDiff		= wScrollBefore - wScrollCurrent;
		elTop			= parseInt(window.getComputedStyle(element).getPropertyValue('top')) + wScrollDiff;

		// scrolled to the very top; element sticks to the top
		if (wScrollCurrent <= 0) {
			element.style.top = '0px';
		// scrolled up; element slides in
		} else if (wScrollDiff > 0) {
			element.style.top = (elTop > 0 ? 0 : elTop) + 'px';
		// scrolled down
		} else if (wScrollDiff < 0) {
			// scrolled to the very bottom; element slides in
			if (wScrollCurrent + wHeight >= dHeight - elHeight) {
				element.style.top = ((elTop = wScrollCurrent + wHeight - dHeight) < 0 ? elTop : 0) + 'px';

			// scrolled down; element slides out
			} else {
				element.style.top = (Math.abs(elTop) > elHeight ? -elHeight : elTop) + 'px';
			}
		}

		wScrollBefore = wScrollCurrent;
	}, 10);

	window.addEventListener('scroll', hideOnScroll);

}(document, window, 0));
