import { gsap } from 'gsap/all';

export function spin() {
	const timeline = gsap.timeline({ delay: 0.5 });

	timeline
		.set('.js-ue-logo', {
			visibility: 'visible'
		})
		.from('#ue-logo--circle', {
			opacity: 0,
			duration: 0.5
		})
		.from('#ue-logo--circle', {
			x: 80,
			rotation: '-720',
			transformOrigin: 'center',
			duration: 2,
			ease: 'back.inOut(0.5)'
		})
		.from('#ue-logo--text', {
			opacity: 0,
			duration: 1
		})

		return timeline;
};
