import $ from 'jquery';
import { draw } from './ue-logo--draw';
import { spin } from './ue-logo--spin';

$(() => {
	if (!$('.js-ue-logo').length){
		return;
	}

	const animType = $('.js-ue-logo svg').data('anim');

	if (animType == 'draw') {
		draw();
	} else if (animType == 'spin') {
		spin();
	}
});
