import Cookies from "js-cookie";
import isTapDevice from "../utilities/isTapDevice";

const checkScrollSpeed = (() => {
	//Function that checks the speed of scrolling
	let lastPos,
		newPos,
		timer,
		delta,
		delay = 50;

	const clear = () => {
		lastPos = null;
		delta = 0;
	};

	clear();

	return () => {
		newPos = window.scrollY;

		if (lastPos != null) {
			delta = newPos - lastPos;
		}

		lastPos = newPos;

		clearTimeout(timer);
		timer = setTimeout(clear, delay);
		return delta;
	};
})();

const setupPopup = () => {
	const id = "uuPopup";

	const popup = document.querySelector(".js-popup");

	if (!popup) return;

	const showPopup = () => {
		if (Cookies.get(id) || window.localStorage.getItem(id)) return;

		popup.classList.remove("d-none");
		window.localStorage.setItem(id, "1");
		Cookies.set(id, "1", { expires: 7 });
	};

	const hidePopup = () => {
		popup.classList.add("d-none");
	};

	function onTappableScroll() {
		if (checkScrollSpeed() < -80) showPopup();
	}

	if (isTapDevice()) {
		document.addEventListener("scroll", onTappableScroll);
	} else {
		document.addEventListener("mouseleave", showPopup);
	}

	popup
		.querySelector(".js-popup__close")
		.addEventListener("click", hidePopup);
};

setupPopup();
