import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const trigger = document.querySelector(".c-number-icons");
const figures = document.querySelectorAll(".c-figure__text");

const tl = gsap.timeline({
	scrollTrigger: {
		trigger,
		start: "middle bottom",
		end: "+=300",
		// scrub: 1,
	},
});

tl.from(figures, {
	textContent: 0,
	duration: 4,
	ease: "power1.in",
	snap: { textContent: 1 },
	onUpdate: function () {
		this.targets()[0].innerHTML = numberWithCommas(
			Math.ceil(this.targets()[0].textContent)
		);
	},
	stagger: {},
});

function numberWithCommas(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
