import $ from 'jquery';

import disableScroll from '../utilities/disableScroll';
import enableScroll from '../utilities/enableScroll';

function openMenu() {
	$('#js-menu').addClass('is-open');
	$('#js-menu-button').addClass('is-menu-open');
	$('#js-menu-underlay').show();

	if(window.matchMedia('(max-width: 1023px)').matches) {
		disableScroll();
	}
}

function closeMenu() {
	$('#js-menu').removeClass('is-open');
	$('#js-menu-button').removeClass('is-menu-open');
	$('#js-menu-underlay').hide();

	if(window.matchMedia('(max-width: 1023px)').matches) {
		enableScroll();
	}
}

$(() => {
	// Open menu on menu button click
	$('#js-menu-button').on('click', event => {
		if($('#js-menu').hasClass('is-open')) {
			closeMenu();
		}
		else {
			openMenu();
		}
	});

	// Close menu when clicking off menu
	$('#js-menu-underlay').on('click', event => {
		closeMenu();
	});

	// Open/close submenus on mobile when button pressed
	$('.js-menu-item-expander').on('click', event => {
		const $expander = $(event.currentTarget);
		$expander.toggleClass('is-menu-open');
		$expander.parent().siblings('.js-menu-submenu').slideToggle('fast');
	});

	// Open submenus on non-mobile when link is hovered
	$('.js-menu-link').on('mouseenter', event => {
		if(window.matchMedia('(min-width: 800px)').matches) {
			// First close any other open submenus
			$('#js-menu .js-menu-submenu').hide();

			$(event.currentTarget).siblings('.js-menu-submenu').show();
		}
	});
 });
