import $ from 'jquery';
import PDFObject from 'pdfobject';

$(() => {
	if (!$('#js-pdf-embed').length) {
		return;
	}

	const pdfLink = $('#js-pdf-embed').attr('data-link');

	PDFObject.embed(pdfLink, '#js-pdf-embed', {
		fallbackLink : `<p>Click the below link to download and view our prices list</p><a class="c-button c-button--green" href="${pdfLink}" target="_blank">View prices</a>`,
		forcePDFJS : true
	});

	if (!PDFObject.supportsPDFs) {
		$('.c-pdf-embed').addClass('c-pdf-embed--mobile');
	}
});
