import $ from "jquery";
import Swiper, { Autoplay, Pagination, Navigation } from "swiper";

Swiper.use([Autoplay, Pagination, Navigation]);

$(() => {
	$(".js-hero-slider .swiper").each((index, slider) => {
		new Swiper(slider, {
			grabCursor: true,
			loop: true,
			autoplay: true,
			pagination: {
				clickable: true,
				el: ".swiper-pagination",
			},
		});
	});

	$(".js-blog-slider .swiper").each((index, slider) => {
		new Swiper(slider, {
			centeredSlides: true,
			grabCursor: true,
			loop: true,
			pagination: {
				clickable: true,
				el: ".swiper-pagination",
			},
			spaceBetween: 30,
			breakpoints: {
				0: {
					slidesPerView: 1.3,
				},
				992: {
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	});

	$(".js-icon-slider .swiper").each((index, slider) => {
		new Swiper(slider, {
			autoplay: {
				delay: 2000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			},
			grabCursor: true,
			loop: true,
			pagination: {
				clickable: true,
				el: ".swiper-pagination",
			},
			breakpoints: {
				0: {
					spaceBetween: 30,
					slidesPerView: 3,
				},
				992: {
					spaceBetween: 50,
					slidesPerView: 4,
				},
			},
		});
	});
});
