import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

let tl = gsap.timeline({
	scrollTrigger: {
		trigger: ".js-areas",
		start: "top center",
		end: "+=200",
		scrub: 1,
	},
});

tl.from(".js-areas__area", {
	scale: 0.5,
	autoAlpha: 0,
	stagger: 0.5,
});

const areas = document.querySelectorAll(".js-areas__area");

gsap.utils.toArray(areas).forEach((area) => {
	const description = area.querySelector(".js-areas__description-hover");

	const tl = gsap
		.timeline({ paused: true, reversed: true })
		.from(description, { autoAlpha: 0, scale: 0 });

	area.addEventListener("mouseenter", () => {
		tl.play();
	});

	area.addEventListener("mouseleave", () => {
		tl.reverse();
	});
});
